import React, { useState } from 'react';
import { Mail } from 'lucide-react';
import axios from 'axios';

const GetInTouch = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [submitStatus, setSubmitStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      await axios.post('https://backend.arcidia.com/api/contact', { name, email, message },{
        'Content-Type': 'application/json'
      });
      setSubmitStatus('Thanks for reaching out! We\'ll get back to you soon.');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error submitting message:', error);
      setSubmitStatus('An error occurred. Please try again.');
    }
  };

  const handleNewsletterSignup = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend.arcidia.com/api/newsletter', { email }, {
        'Content-Type': 'application/json'
      });
      setSubmitStatus('Thanks for signing up for our newsletter!');
      setEmail('');
    } catch (error) {
      console.error('Error signing up for newsletter:', error);
      setSubmitStatus('An error occurred. Please try again.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-4xl font-bold mb-8 text-center text-neutral">Get in Touch</h2>
      
      <div className="grid md:grid-cols-2 gap-8">
        {/* Contact Form */}
        <div className="bg-surface p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold mb-4 text-primary">Send us a message</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-text-secondary mb-2">Your Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-4 py-2 rounded-md bg-background text-text-primary border border-text-secondary focus:outline-none focus:border-primary"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-text-secondary mb-2">Your Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 rounded-md bg-background text-text-primary border border-text-secondary focus:outline-none focus:border-primary"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-text-secondary mb-2">Your Message</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full px-4 py-2 rounded-md bg-background text-text-primary border border-text-secondary focus:outline-none focus:border-primary"
                rows="4"
                required
              ></textarea>
            </div>
            <button type="submit" className="bg-primary text-text-primary px-6 py-2 rounded-full hover:bg-opacity-80 transition duration-300">
              Send Message
            </button>
          </form>
        </div>

        {/* Newsletter Signup */}
        <div className="bg-surface p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold mb-4 text-primary">Sign up for our newsletter</h3>
          <p className="mb-4 text-text-secondary">Pop your email in below to join our VIP list – no spam, just the latest tech updates, new idea drops, and maybe a meme or two to keep things fun!</p>
          <form onSubmit={handleNewsletterSignup}>
            <div className="relative mb-4">
              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-text-secondary" size={20} />
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-full bg-background text-text-primary border border-text-secondary focus:outline-none focus:border-primary"
                required
              />
            </div>
            <button type="submit" className="bg-primary text-text-primary px-6 py-2 rounded-full hover:bg-opacity-80 transition duration-300">
              Subscribe
            </button>
          </form>
        </div>
      </div>

      {submitStatus && (
        <p className="mt-8 text-center text-green-500">{submitStatus}</p>
      )}
    </div>
  );
};

export default GetInTouch;
