import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, ChevronDown } from 'lucide-react';

const CategoryTile = ({ category }) => (
  <Link
    to={`/ideas/category/${encodeURIComponent(category)}`}
    className="group relative bg-surface overflow-hidden p-6 rounded-2xl transition-all duration-300 transform hover:scale-105 hover:shadow-glow"
  >
    <div className="absolute inset-0 bg-gradient-to-br from-primary to-secondary opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
    <h3 className="text-lg font-semibold mb-2 text-primary group-hover:text-white transition-colors duration-300">{category}</h3>
    <ArrowRight className="text-secondary group-hover:text-white mt-2 transition-colors duration-300 transform group-hover:translate-x-1" size={20} />
    <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-primary to-secondary transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
  </Link>
);

const Home = ({ categories }) => {
  const scrollToCategories = () => {
    const categoriesSection = document.getElementById('categories-section');
    if (categoriesSection) {
      categoriesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex flex-col items-center">
      {/* Hero Section */}
      <div className="min-h-screen flex flex-col justify-center items-center text-center px-4">
        <h1 className="text-6xl font-bold mb-6 animate-fade-in-up">Innovate with Arcidia</h1>
        <p className="text-xl mb-12 text-text-secondary max-w-2xl animate-fade-in-up animation-delay-200">
          Discover your next ground-breaking idea here.
        </p>
        <Link 
          to="/ideas" 
          className="bg-primary text-text-primary px-8 py-3 rounded-full hover:bg-opacity-80 transition duration-300 flex items-center mb-16 animate-fade-in-up animation-delay-400"
        >
          Explore All Ideas <ArrowRight className="ml-2" size={18} />
        </Link>
        <button 
          onClick={scrollToCategories}
          className="text-text-secondary hover:text-primary transition duration-300 flex flex-col items-center animate-fade-in-up animation-delay-600"
        >
          Builder's Vision
          <ChevronDown className="mt-2 animate-bounce" size={24} />
        </button>
      </div>

      {/* Categories Section */}
      <div id="categories-section" className="w-full max-w-4xl px-4 py-16">
        <h2 className="text-4xl font-bold mb-8 text-center">Vision</h2>
        <p className="text-xl mb-12 text-center text-text-secondary max-w-2xl mx-auto leading-relaxed space-y-4 animate-fade-in-up animation-delay-200">
  We've navigated through the internet to sift through massive volumes of user feedback and discussions, in order to harvest user-generated pain points.
  <br />
  This data is then processed by an LLM model that analyzes and distills this data to identify underlying trends and patterns in user dissatisfaction. It then generates a plethora of innovative start-up ideas.
  <br />
  <br />
  Dive into our curated categories and explore the hundreds of ideas we've generated—be it a school project, university venture, side hustle, or your next million-dollar company!
  Whether you're on the hunt for your next big venture or just geeking out over new possibilities, our repository has something for everyone in the realm of cutting-edge innovation!

  <br />
  <br />
  Get in touch with us so we can update you when we drop more ideas. We have more ideas in the pipeline for you to see market trends in user pain points being reported online and will be releasing every week.
</p>


</div>
    </div>
  );
};

export default Home;