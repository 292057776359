import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import axios from 'axios';

const IdeaDetails = ({ ideas }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const idea = ideas.find((idea) => idea.id === parseInt(id));
  const [showNewsletterPrompt, setShowNewsletterPrompt] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const checkViewedIdeas = () => {
      const viewedIdeas = JSON.parse(localStorage.getItem('viewedIdeas') || '[]');
      const isSubscribed = localStorage.getItem('isSubscribed') === 'true';
      
      if (!isSubscribed && viewedIdeas.length >= 2 && !viewedIdeas.includes(parseInt(id))) {
        setShowNewsletterPrompt(true);
      } else {
        if (!viewedIdeas.includes(parseInt(id))) {
          const updatedViewedIdeas = [...viewedIdeas, parseInt(id)];
          localStorage.setItem('viewedIdeas', JSON.stringify(updatedViewedIdeas));
        }
      }
    };

    checkViewedIdeas();
  }, [id]);

  const handleNewsletterSignup = async (e) => {
    e.preventDefault();
    console.log('Newsletter signup initiated');
    try {
      const response = await axios.post(
        'https://backend.arcidia.com/api/newsletter',
        { email },
        {
          headers: { 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*' // Add this line
          },
          withCredentials: false // Add this line
        }
      );
      
      console.log('Server response:', response);
      
      setShowNewsletterPrompt(false);
      setEmail('');
      localStorage.setItem('isSubscribed', 'true');
      setIsSubscribed(true);
      // Clear viewed ideas count
      localStorage.removeItem('viewedIdeas');
      // Show success message or update UI as needed
      alert('Thank you for subscribing! You now have unlimited access to ideas.');
    } catch (error) {
      console.error('Error signing up for newsletter:', error);
      alert('There was an error signing up. Please try again.');
    }
  };

  if (!idea) {
    return <div className="text-text-primary">Idea not found</div>;
  }

  if (showNewsletterPrompt) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-surface p-8 rounded-lg max-w-md w-full">
          <h3 className="text-2xl font-semibold mb-4 text-primary">Subscribe to View More Ideas</h3>
          <p className="mb-4 text-text-secondary">You've viewed 2 ideas. Sign up for our newsletter to unlock unlimited access!</p>
          <form onSubmit={handleNewsletterSignup}>
            <div className="relative mb-4">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 rounded-full bg-background text-text-primary border border-text-secondary focus:outline-none focus:border-primary"
                required
              />
            </div>
            <button type="submit" className="bg-primary text-text-primary px-6 py-2 rounded-full hover:bg-opacity-80 transition duration-300 w-full">
              Subscribe
            </button>
          </form>
          <button 
            onClick={() => navigate('/ideas')} 
            className="mt-4 text-text-secondary hover:text-primary transition duration-300"
          >
            Back to Ideas
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-surface p-8 rounded-lg shadow-md">
      <Link to="/ideas" className="text-secondary-light hover:underline flex items-center mb-4">
        <ChevronLeft className="mr-1" /> Back to Ideas
      </Link>
      <h2 className="text-3xl font-bold mb-4 text-secondary-light">{idea["Startup Name"]}</h2>
      <p className="text-xl text-text-secondary mb-6">{idea.Category}</p>
      <div className="space-y-4">
        <Section title="Elevator Pitch" content={idea["Elevator Pitch"]} />
        <Section title="Problem" content={idea.Problem} />
        <Section title="Solution" content={idea.Solution} />
        <Section title="Target Market" content={idea["Target Market"]} />
        <Section title="Market Size" content={idea["Market Size"]} />
        <Section title="Business Model" content={idea["Business Model"]} />
        <Section title="Competitive Advantage" content={idea["Competitive Advantage"]} />
        <Section title="Financial Projections">
          <ul className="list-disc pl-5 text-text-primary">
            <li>Initial Investment: {idea["Financial Projections"]["Estimated initial investment required"]}</li>
            <li>Projected Revenue (3 years): {idea["Financial Projections"]["Projected annual revenue after 3 years"]}</li>
            <li>Time to Break-even: {idea["Financial Projections"]["Estimated time to break-even"]}</li>
          </ul>
        </Section>
        <Section title="Key Risks">
          <ul className="list-disc pl-5 text-text-primary">
            {idea["Key Risks"].map((risk, index) => (
              <li key={index}>{risk}</li>
            ))}
          </ul>
        </Section>
        <Section title="Impact" content={idea.Impact} />
      </div>
    </div>
  );
};

const Section = ({ title, content, children }) => (
  <div>
    <h3 className="text-xl font-semibold mb-2 text-secondary-light">{title}</h3>
    {content ? <p className="text-text-primary">{content}</p> : children}
  </div>
);

export default IdeaDetails;