import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowRight, Search, Mail } from 'lucide-react';
import axios from 'axios';

const IdeasList = ({ ideas }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [viewedIdeas, setViewedIdeas] = useState(() => {
    const saved = localStorage.getItem('viewedIdeas');
    return saved ? JSON.parse(saved) : [];
  });
  const [showNewsletterPrompt, setShowNewsletterPrompt] = useState(false);
  const [email, setEmail] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const listRef = useRef(null);

  const categories = useMemo(() => {
    const allCategories = ['All', ...new Set(ideas.map(idea => idea.Category))];
    return allCategories.sort();
  }, [ideas]);

  const popularCategories = useMemo(() => {
    return ['All', 'AI/ML', 'SaaS', 'E-commerce', 'FinTech', 'Health & Fitness'];
  }, []);

  const filteredIdeas = useMemo(() => {
    return ideas.filter(idea => 
      (selectedCategory === 'All' || idea.Category === selectedCategory) &&
      (idea["Startup Name"].toLowerCase().includes(searchTerm.toLowerCase()) ||
       idea.Category.toLowerCase().includes(searchTerm.toLowerCase()) ||
       idea["Elevator Pitch"].toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [ideas, selectedCategory, searchTerm]);

  useEffect(() => {
    localStorage.setItem('viewedIdeas', JSON.stringify(viewedIdeas));
  }, [viewedIdeas]);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem('ideasListScrollPosition');
    if (savedScrollPosition !== null) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      }, 100);
      sessionStorage.removeItem('ideasListScrollPosition');
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      sessionStorage.setItem('ideasListScrollPosition', window.pageYOffset.toString());
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleIdeaClick = (ideaId) => {
    const viewedIdeas = JSON.parse(localStorage.getItem('viewedIdeas') || '[]');
    if (viewedIdeas.length >= 2 && !viewedIdeas.includes(ideaId)) {
      // The newsletter prompt will be shown in the IdeaDetails component
      navigate(`/ideas/${ideaId}`);
    } else {
      navigate(`/ideas/${ideaId}`);
    }
  };

  const handleNewsletterSignup = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend.arcidia.com/api/newsletter', { email }, {
        'Content-Type': 'application/json'
      });
      setShowNewsletterPrompt(false);
      setEmail('');
      localStorage.removeItem('viewedIdeas');
      setViewedIdeas([]);
    } catch (error) {
      console.error('Error signing up for newsletter:', error);
    }
  };

  return (
    <div className="container mx-auto px-4" ref={listRef}>
      <h2 className="text-4xl font-bold mb-8 text-center text-neutral">Explore Ideas</h2>
      
      {/* Search Bar */}
      <div className="flex justify-center mb-8">
        <div className="relative w-full max-w-md">
          <input
            type="text"
            placeholder="Search ideas or categories..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 pl-10 rounded-full bg-surface text-text-primary border border-text-dark focus:outline-none focus:border-primary"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-text-dark" size={20} />
        </div>
      </div>

      {/* Popular Categories */}
      <div className="flex flex-wrap justify-center gap-2 mb-8">
        {popularCategories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`px-4 py-2 rounded-full transition-all duration-300 ${
              selectedCategory === category
                ? 'bg-primary text-text-primary'
                : 'bg-surface text-text-secondary hover:bg-primary-light hover:text-text-primary'
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Ideas Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredIdeas.map((idea) => (
          <div 
            key={idea.id} 
            onClick={() => handleIdeaClick(idea.id)}
            className="bg-surface p-6 rounded-2xl transition duration-300 flex flex-col justify-between group hover:shadow-glow cursor-pointer"
          >
            <div>
              <h3 className="text-xl font-semibold mb-2 text-primary group-hover:text-primary-light transition-colors duration-300">{idea["Startup Name"]}</h3>
              <p className="text-text-secondary mb-2">{idea.Category}</p>
              <p className="text-sm text-text-primary">{idea["Elevator Pitch"].slice(0, 100)}...</p>
            </div>
            <div className="flex justify-end mt-4">
              <ArrowRight className="text-primary group-hover:text-primary-light group-hover:translate-x-1 transition-all duration-300" size={20} />
            </div>
          </div>
        ))}
      </div>

      {filteredIdeas.length === 0 && (
        <p className="text-center text-text-secondary mt-8">No ideas found. Try adjusting your search or category selection.</p>
      )}

      {/* Newsletter Signup Prompt */}
      {showNewsletterPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-surface p-8 rounded-lg max-w-md w-full">
            <h3 className="text-2xl font-semibold mb-4 text-primary">Subscribe to View More Ideas</h3>
            <p className="mb-4 text-text-secondary">You've viewed 2 ideas. Sign up for our newsletter to unlock unlimited access!</p>
            <form onSubmit={handleNewsletterSignup}>
              <div className="relative mb-4">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-text-secondary" size={20} />
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-full bg-background text-text-primary border border-text-secondary focus:outline-none focus:border-primary"
                  required
                />
              </div>
              <button type="submit" className="bg-primary text-text-primary px-6 py-2 rounded-full hover:bg-opacity-80 transition duration-300 w-full">
                Subscribe
              </button>
            </form>
            <button 
              onClick={() => setShowNewsletterPrompt(false)} 
              className="mt-4 text-text-secondary hover:text-primary transition duration-300"
            >
              Maybe later
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IdeasList;